<template>
  <div>
    <div
      class="w-42 md:w-40 lg:w-44 2xl:w-80 h-28 bg-white p-4 mt-4 box-content font-proximaMedium cursor-pointer"
      @click="whichBreed(service)"
      @mouseover="mouseOverFn"
      @mouseleave="mouseLeaveFn"
    >
      <div>
        <div v-if="!showMessage" class="flex flex-row">
          <img
            class="w-4/5 object-scale-down h-28 flex justify-center items-center"
            :src="require('@/assets/images/dogbreederservices/' + img[0].img + '.png')"
            :alt="img[0].img"
          />
          <div class="w-1/5 flex justify-end">
            <div
              class="w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center"
            >
              <div>{{ formattedIndex(i) }}</div>
            </div>
          </div>
        </div>
        <div v-else class="mt-2">
          <div class="flex justify-center">
            <div
              class="w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center"
            >
              <div>{{ formattedIndex(i) }}</div>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <div class="mt-6">{{ service.group }}</div>
          </div>
        </div>
      </div>
    </div>
    <ModalsConfirmVue
      :show="dogBreedListModals"
      @update:show="dogBreedListModals = $event"
    >
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-10 text-3xl font-proximaMedium cursor-pointer z-10"
          @click="closeModals"
        >
          ✖
        </div>
        <!-- <h1 class="text-2xl">Favorite Services</h1> -->
      </div>
      <div slot="actionText" class="mt-6" @click.stop>
        <div class="mb-4" v-for="(data, i) in service.sections" :key="i">
          <div class="my-4">
            <div
              class="text-xl text-primary font-semibold font-proximaMedium text-left rounded-md"
            >
              {{ "Section " + Number(i) + " - " + data.section }}
            </div>
            <ul class="font-proximaLight text-base">
              <li
                class="py-2 cursor-pointer"
                v-for="(data, i) in data.breeds"
                :key="i"
                @click="choosenDog(data)"
              >
                {{ data.breedName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirmVue>
  </div>
</template>

<script>
import ModalsConfirmVue from "../../modals/ModalsConfirm.vue";
import DogBreeds from "../../../json/DogBreeds.json";

export default {
  components: {
    ModalsConfirmVue,
  },
  props: ["service", "i", "img"],
  data() {
    return {
      showMessage: false,
      dogBreeds: DogBreeds,
      dogBreedListModals: false,
      breedMatch: [],
    };
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeyPress); // Listen for keydown event
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPress); // Remove event listener when component is destroyed
  },

  methods: {
    handleKeyPress(event) {
      console.log("event", event);
      if (event.key === "Escape") {
        this.closeModals(); // Close modal when 'Escape' is pressed
      }
    },

    formattedIndex(index) {
      return (index).toString().padStart(2, "0");
    },
    mouseOverFn() {
      this.showMessage = true;
    },
    mouseLeaveFn() {
      this.showMessage = false;
    },
    whichBreed(service) {
      // console.log(i);
      // console.log(service.title);
      const targetTitle = service.group;
      // console.log(this.service);
      /*  this.breedMatch = this.service.find(
        (obj) => obj.group === targetTitle
      ).sections; */
      this.openModals();
      // console.log(match);
    },
    choosenDog(i) {
      localStorage.setItem("breedNameSearch", i.breedName);
      this.$router.push({
        name: "BreederSearchResults",
      });
    },
    openModals() {
      this.dogBreedListModals = true;
    },
    closeModals() {
      this.dogBreedListModals = false;
    },
    confirmAction() {
      // Handle confirmation logic
      this.closeModals();
    },
  },
};
</script>

<style>
</style>