<template>
  <div
    class="w-11/12 md:w-10/12 flex flex-wrap items-start md:items-center justify-between font-proximaMedium"
  >
    <div class="mb-4 w-full block md:hidden">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
          v-model="searchWord"
          @keyup.enter="searchMethod"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
    <div class="w-44 md:w-2/12 py-3 px-6 flex items-center justify-between">
      <!--   <input class="w-10/12 md:w-8/12" type="text" placeholder="Postcode" />
      <LocationIcon
        class="ml-4 mt-1"
        iconColor="#0AC0CB"
        heigth="18"
        width="18"
      /> -->
    </div>
    <div class="md:w-5/12 lg:w-5/12 hidden md:block">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
          v-model="searchWord"
          @keyup.enter="searchMethod"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>

    <div
      class="w-44 md:w-max py-3 px-6 flex items-center justify-between bg-primary text-white rounded-md cursor-pointer"
      @click="openFavoriteModals"
    >
      <span>Favorite</span>
      <HeartIcon
        class="ml-4 mt-1"
        :iconColor="favoriteComp"
        heigth="18"
        width="18"
      />
    </div>

    <!-- favorite modals -->
    <ModalsConfirm :show="favoriteModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-10 text-3xl font-proximaMedium cursor-pointer"
          @click="favoriteModals = false"
        >
          ✖
        </div>
        <h1 class="text-2xl">Favorite Services</h1>
      </div>
      <div slot="actionText" class="mt-6">
        <div
          class="mb-4 flex items-center justify-between"
          v-for="(data, i) in favouriteServices"
          :key="i"
        >
          <div class="flex items-center">
            <img :src="require('@/assets/images/pp-3.png')" alt="image" />
            <div class="ml-2 text-left">
              <h5 class="font-proximaMedium text-primary text-base">
                {{ data.favouriteServiceEmailAddress }}
              </h5>
              <!-- <span>{{ data.address }}</span> -->
            </div>
          </div>
          <img src="@/assets/images/close-icon.png" alt="close-icon" />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- favorite modals end -->
  </div>
</template>

<script>
import HeartIcon from "../../icons/HeartIcon.vue";
import LocationIcon from "../../icons/LocationIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import InputForm from "../../forms/InputForm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import FormError from "../../warning/FormError.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeartIcon,
    LocationIcon,
    ModalsConfirm,
    InputForm,
    SubmitButton,
    BorderedButton,
    FormError,
  },
  data() {
    return {
      searchWord: "",
      isFavorite: true,
      favoriteModals: false,
    };
  },
  computed: {
    ...mapGetters({
      serviceDirectory: "servicedirectory/getServiceDirectories",
      favouriteServices: "contact/getFavouriteServices",
    }),

    errorForm() {
      return this.$store.state.errorForm;
    },

    favoriteComp() {
      if (this.isFavorite) {
        return "#ff0c00";
      } else {
        return "#fff";
      }
    },
  },

  async mounted() {
    this.getFavouriteServices(localStorage.getItem("email"));
  },

  methods: {
    ...mapActions({
      filterGroupsSections: "breederdirectory/filterGroupsSections",
      getFavouriteServices: "contact/getFavouriteServices",
    }),

    openFavoriteModals() {
      if (this.isFavorite) {
        this.favoriteModals = true;
      }
    },

    async searchMethod() {
      console.log(this.searchWord);
      this.filterGroupsSections(this.searchWord);
      /* this.$router.push({
        name: "BreederSearchResults",
        params: { data: this.serviceDirectory },
      }); */

      //this.$emit("resultSearch", result);
    },
  },
};
</script>
