<template>
  <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 flex items-start">
    <div v-for="(service, i) in groupsSections" :key="i">
      <ServicesVue
        :service="service"
        :i="service.id"
        :img="getImg(service)"
        @whichBreed="whichBreed(service, i)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ServicesVue from "./Services.vue";

export default {
  components: {
    ServicesVue,
  },
  data() {
    return {
      serviceList: [
        { id: 1, img: "1-AustralianCattleDog1" },
        { id: 2, img: "2-Dobermann1" },
        { id: 3, img: "3-JackRusselTerrier1" },
        { id: 4, img: "4-Dachsund1" },
        { id: 5, img: "5-SiberianHusky1" },
        { id: 6, img: "6-Beagle1" },
        { id: 7, img: "7-IrishSetter1" },
        { id: 8, img: "8-Labrador1" },
        { id: 9, img: "9-Poodle1" },
        { id: 10, img: "10-Saluki1" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      serviceDirectory: "servicedirectory/getServiceDirectories",
      groupsSections: "breederdirectory/getGroupsSections",
    }),
  },
  created() {
    this.getGroupsSections();
    console.log(this.groupsSections);
  },

  methods: {
    ...mapActions({
      getServiceDirectoryType1: "servicedirectory/getServiceDirectoryType1",
      getServiceDirectoryType2: "servicedirectory/getServiceDirectoryType2",
      getServiceDirectoryType3: "servicedirectory/getServiceDirectoryType3",
      getServiceDirectoryType4: "servicedirectory/getServiceDirectoryType4",
      getServiceDirectoryType6: "servicedirectory/getServiceDirectoryType6",
      getServiceDirectoryType8: "servicedirectory/getServiceDirectoryType8",
      getGroupsSections: "breederdirectory/getGroupsSections",
    }),
    getImg(item) {
      console.log(
        "this.serviceList.filter((e) => e.id == item.id);",
        this.serviceList.filter((e) => e.id == item.id)
      );
      return this.serviceList.filter((e) => e.id == item.id);
    },
  },
};
</script>

<style></style>
